import InputBase from "@material-ui/core/InputBase"
import InputLabel from "@material-ui/core/InputLabel"
import Tooltip from "@material-ui/core/Tooltip"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import EndAdornmentIcon from "@material-ui/icons/ExpandMore"
import LeafIcon from "@material-ui/icons/SubdirectoryArrowRight"
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab"
import { default as classNames, default as classnames } from "classnames"

import BaseInput, { BaseInputProps } from "./BaseInput"

export interface AutocompleteOption {
  disabled?: boolean
  group?: string
  parents?: string[]
  /**
   * Shown as faced but not actually disabled. Used to fix incompatibility
   * of disable and tooltip.
   * @TODO: improve by adding proper disable state and tooltip
   */
  softDisabled?: boolean
  title: string
  tooltip?: string
  value: string
}

interface AutocompleteProps extends BaseInputProps {
  variant?: "hintsaGold" | "plain"
  className?: string
  disabled?: boolean
  emptyOptionLabel?: string
  fullWidth?: boolean
  grouped?: boolean
  id: string
  label?: string
  labelClass?: string
  inputClass?: string
  margin?: boolean
  onChange?: (value: string) => void
  options: AutocompleteOption[]
  placeholder?: string
  showEmptyOption?: boolean
  value: string
}

class Autocomplete extends BaseInput<AutocompleteProps & WithStyles<typeof styles>, {}> {
  render() {
    const { fullWidth, options, id, grouped, value, showEmptyOption, emptyOptionLabel } = this.props

    let optionValue = options.find(option => option.value === value)
    const emptyOption = { value: undefined, title: emptyOptionLabel ?? this.txt("none") }

    if (showEmptyOption && !optionValue) optionValue = emptyOption

    const optionsWithEmpty = showEmptyOption ? [emptyOption, ...options] : options

    return (
      <MuiAutocomplete
        id={id}
        fullWidth={fullWidth}
        options={optionsWithEmpty}
        renderInput={this.renderInput}
        getOptionLabel={this.getOptionLabel}
        groupBy={grouped ? this.groupBy : undefined}
        onChange={this.onChange}
        renderOption={this.renderOption}
        getOptionDisabled={(option: AutocompleteOption) => option?.disabled}
        value={optionValue ?? null}
        getOptionSelected={(option: AutocompleteOption, compareValue: AutocompleteOption) =>
          (option?.value ?? null) === (compareValue?.value ?? null)
        }
      />
    )
  }

  private getOptionLabel = (option: AutocompleteOption) => {
    let label = option.title

    if (option.parents?.length) {
      label += ` (${option.parents.join(" / ")})`
    }

    return label
  }

  private groupBy = (option: AutocompleteOption) => option.group

  private onChange = (event, autocompleteOption: AutocompleteOption) => {
    if (autocompleteOption?.softDisabled) {
      return this.forceUpdate()
    }

    this.props.onChange(autocompleteOption?.value)
  }

  private renderInput = params => {
    const { classes, variant, fullWidth, placeholder, margin, className, label, labelClass, inputClass } = this.props

    return (
      <div ref={params.InputProps.ref} className={classNames(margin !== false && classes.margin, className)}>
        {label && (
          <InputLabel
            {...params.InputLabelProps}
            shrink
            className={classNames(!variant && classes.formLabel, labelClass)}
          >
            {label}
          </InputLabel>
        )}

        <InputBase
          {...params.inputProps}
          autoComplete="off"
          fullWidth={fullWidth}
          placeholder={placeholder}
          type="search"
          endAdornment={<EndAdornmentIcon color="secondary" />}
          classes={{
            root: variant === "hintsaGold" && classes.bootstrapRoot,
            focused: variant === "hintsaGold" && classes.inputFocused,
            input: classnames(variant === "hintsaGold" && classes.bootstrapInput, inputClass)
          }}
        />
      </div>
    )
  }

  private renderOption = (option: AutocompleteOption) => {
    const { classes } = this.props

    let leaf
    if (option.parents?.length) {
      const marginLeft = `${option.parents.length - 1}em`
      leaf = <LeafIcon className={classes.leaf} fontSize="small" style={{ marginLeft }} />
    }

    const component = (
      <div className={option.softDisabled ? classes.softDisabled : undefined}>
        {leaf}
        {option.title}
      </div>
    )

    return option.tooltip ? <Tooltip title={option.tooltip}>{component}</Tooltip> : component
  }
}

const styles = ({ palette, transitions, typography }: Theme) =>
  createStyles({
    bootstrapRoot: {
      backgroundColor: BaseInput.theme.colors.inputBackgroundColor.toString(),
      borderRadius: 4,
      border: "1px solid " + palette.divider,
      transition: transitions.create(["border-color", "box-shadow"]),

      "label + &": { marginTop: "0 !important" }
    },
    bootstrapInput: {
      borderRadius: 4,
      fontSize: typography.fontSize,
      padding: "1rem"
    },
    inputFocused: {
      borderColor: BaseInput.theme.colors.primary.toString(),
      boxShadow: `0 0 0 0.2rem ${BaseInput.theme.colors.secondary.fade(0.25).toRGBA()}`
    },
    formLabel: {
      textTransform: "uppercase",
      fontSize: "0.9rem",
      marginLeft: "0.5rem",
      letterSpacing: "1.1px",
      whiteSpace: "nowrap"
    },
    margin: {
      marginTop: "0.5rem",
      marginBottom: "0.5rem"
    },
    leaf: {
      opacity: 0.15
    },
    softDisabled: {
      color: BaseInput.theme.colors.deEmphasizedColor.toString(),
      cursor: "default"
    }
  })

export default withStyles<any, any, AutocompleteProps>(styles)(Autocomplete)
